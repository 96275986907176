<div>
  <span class="tc-dust font-14px">Last updated 3 months ago</span>
  <div class="mt-24 d-flex f-column gap-16">
    <app-roam-input [name]="'Current Password'"></app-roam-input>
    <app-roam-input [name]="'New Password'"></app-roam-input>
    <app-roam-input [name]="'Confirm Password'"></app-roam-input>
  </div>
  <hr class="mt-24 mb-24">
  <div>
      <app-login-socmed></app-login-socmed>
  </div>
</div>

<br>
<div id="'container1'">
    <div *ngFor="let row of calendars">
        <div>{{row.name}}</div>
    </div>
</div>

<div id="'container2'">
    <div *ngFor="let row of events">
        <div>{{row.subject}} starts {{row.start.dateTime | date }} to {{row.end.dateTime | date }}</div>
    </div>
</div>

<div id="'container3'">
    <div *ngFor="let row of folders">
        <div>{{row.displayName}}</div>
    </div>
</div>

<div id="'container4'">
    <div *ngFor="let row of messages">
        <div>from {{row.sender.emailAddress.name}} at {{row.sentDateTime | date }}: {{row.subject}}</div>
    </div>
</div>

<div id="'container5'">
    <div *ngFor="let row of googleEvents">
        <div>{{row.summary}} starts {{row.date | date}}</div>
    </div>
</div>

<div id="'container6'">
    <div *ngFor="let row of googleMessages">
        <div>from {{row.from}} at {{row.date | date }}: {{row.subject}}</div>
    </div>
</div>

<div class="footer-container">
  <span class="text-btn-underline"
        (click)="cancel()">Cancel
  </span>
  <app-roam-button [label]="'Save'"
                   [class]="'btn-primary'"
  ></app-roam-button>
</div>
